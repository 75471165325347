import { FooterProps } from 'dss-ui-library';

export const footerNC: FooterProps = {
  brandName: 'NetCologne',
  tiles: [
    {
      headline: 'Produkte',
      e2e: 'products',
      links: [
        {
          text: 'Glasfaser',
          e2e: 'glasfaser',
          url: '/privatkunden/glasfaser/',
        },
        {
          text: 'DSL/Kabel',
          e2e: 'dsl-kabel',
          url: '/privatkunden/dsl/',
        },
        {
          text: 'Zusatzoptionen',
          e2e: 'additional',
          url: '/privatkunden/internet-zusatzoptionen/',
        },
        {
          text: 'TV',
          e2e: 'tv',
          url: '/privatkunden/fernsehen/',
        },
        {
          text: 'Mobilfunk',
          e2e: 'mobile',
          url: 'https://mobilshop.netcologne.de/netcologne-shop/tarif/list/',
          target: '_blank',
        },
        {
          text: 'Domains & Homepages',
          e2e: 'domains',
          url: '/privatkunden/internet-zusatzoptionen/domain-and-hosting/',
        },
        {
          text: 'NetCologne empfehlen',
          e2e: 'friends',
          url: '/privatkunden/empfehlen/',
        },
      ],
    },
    {
      headline: 'Unternehmen',
      e2e: 'company',
      links: [
        {
          text: 'Über NetCologne',
          e2e: 'about',
          url: 'https://netcologne-unternehmen.de/',
          target: '_blank',
        },
        {
          text: 'Karriere',
          e2e: 'jobs',
          url: 'https://netcologne-unternehmen.de/karriere/',
          target: '_blank',
        },
        {
          text: 'Presse',
          e2e: 'newsroom',
          url: 'https://netcologne-unternehmen.de/presse/',
          target: '_blank',
        },
        {
          text: 'Vertriebspartner',
          e2e: 'partners',
          url: '/privatkunden/vertriebspartner/',
        },
        {
          text: 'Wohnungswirtschaft',
          e2e: 'appartment',
          url: '/geschaeftskunden/wohnungswirtschaft/ihre-vorteile/',
        },
        {
          text: 'NetKompakt',
          e2e: 'net-kompakt',
          url: 'https://netkompakt.koeln/',
          target: '_blank',
        },
        {
          text: 'koeln.de',
          e2e: 'koeln.de',
          url: 'https://www.koeln.de/',
          target: '_blank',
        },
      ],
    },
    {
      headline: 'Ausbau',
      e2e: 'broadband-expansion',
      links: [
        {
          text: 'Bauherren-Service',
          e2e: 'land-owners',
          url: '/privatkunden/ausbau/bauherren/',
        },
        {
          text: 'Grundstückseigentümer-Erklärung',
          e2e: 'owner-declaration',
          url: '/glasfaser/',
        },
        {
          text: 'Ausbaugebiete',
          e2e: 'areas',
          url: '/privatkunden/hilfe/ausbau/',
        },
      ],
    },
    {
      headline: 'Service & Hilfe',
      e2e: 'help',
      links: [
        {
          text: 'Kontakt',
          e2e: 'contact',
          url: '/privatkunden/kontakt/',
        },
        {
          text: 'Hilfethemen im Überblick',
          e2e: 'help-overview',
          url: '/privatkunden/hilfe/',
        },
        {
          text: 'WLAN optimieren',
          e2e: 'wifi-optimize',
          url: '/privatkunden/hilfe/wlan/',
        },
        {
          text: 'Vertrag & Daten',
          e2e: 'contracts',
          url: '/privatkunden/faq/vertrag-meine-daten/',
        },
        {
          text: 'Verträge hier kündigen',
          e2e: 'cancel-contract',
          url: '/privatkunden/hilfe/vertrag-kuendigen/',
        },
        {
          text: 'Mail & Cloud',
          e2e: 'mail-cloud',
          url: '/privatkunden/faq/mail-cloud/',
        },
      ],
    },
  ],
  meta: [
    {
      text: 'Impressum',
      url: '/impressum/',
      e2e: 'menu-item-imprint',
    },
    {
      text: 'AGB',
      url: '/agb/',
      e2e: 'menu-item-tnc',
    },
    {
      text: 'TKG-Kundenschutz',
      e2e: 'tkg-customer-protect',
      url: '/privatkunden/tkg-kundenschutz/',
    },
    {
      text: 'Datenschutz',
      url: '/datenschutz/',
      e2e: 'menu-item-legal',
    },
    {
      text: 'Compliance',
      e2e: 'compliance',
      url: 'https://www.netcologne.de/compliance/',
    },
  ],
} as const;
