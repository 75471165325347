import dynamic from 'next/dynamic';
import { getEnv, isNC } from '@ncs-frontend-monorepo/utils';
import { FooterType } from '../Layout';
import { footerNC } from './footerNC';
import { footerNA } from './footerNA';
import { getDefaultSocialMediaItems } from 'dss-ui-library';

const Footer = dynamic(import('dss-ui-library').then(({ Footer }) => Footer));

const FooterSmall = dynamic(
  import('../FooterSmall').then(({ FooterSmall }) => FooterSmall),
);

interface FooterSelectorProps {
  type: FooterType;
}

export function FooterSelector({ type }: FooterSelectorProps): React.ReactNode {
  switch (type) {
    case FooterType.default:
      return (
        <Footer
          {...(isNC() ? footerNC : footerNA)}
          socialMediaItems={getDefaultSocialMediaItems(getEnv().SITE)}
        />
      );

    case FooterType.order:
      return <FooterSmall />;

    default:
      return null;
  }
}
